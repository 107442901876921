// _config.sass

// Colors
$color-primary1 : #3399CC;
$color-primary2 : #4D4D4D;
$color-secondary1 : #FF7700;
$color-secondary2 : #F5DF09;

$light-primary1 : #85C2E0;
$very-light-primary1 : #D6EBF5;
$very-light-primary2 : #e2e2e2;
$light-primary2 : #999999;
$youtube-red : #c4302b;

$primary : $color-primary1;
$primary-light : $light-primary1;
$primary-hover : $light-primary1;

$padding-medium : 7px;
$footer-background-color: $very-light-primary1;

$navbar-padding-horizontal : 7rem;
$navbar-padding-vertical : 0rem;

$box-background-color: $very-light-primary1;
//$box-shadow: 0 1px 1px rgba($primary, 1.0), 0 0 0 1px rgba($primary, 1.0)
$box-shadow: 0 0px 0px rgba($primary, 1.0), 0 0 0 0px rgba($primary, 1.0);

$family-sans-serif : "acumin-pro", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

// tweak standard front stuff just a bit
$body-font-size : 1.05em;
$body-line-height : 1.55;

// tab customization
$tabs-link-active-border-bottom-color: $primary;
$tabs-link-active-color: $primary;

$tabs-toggle-link-active-background-color: $primary;
$tabs-toggle-link-active-border-color: $primary;
