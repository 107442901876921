// gearhowto_styles.sass

////
// import some other sheets
////
// variable/standard setting configuration
@import "config";

@import "custom-sass-library/css-spacing";

@import "bulma";


// custom styles to supplement bulma

// pushes the footer to the bottom of each page
body.default {
  display: flex;
  // use this if navbar is NOT fixed (default)
  min-height: 100vh;
  flex-direction: column; }

body.fixed {
  display: flex;
  // use this if navbar is fixed
  min-height: calc(100vh - #{$navbar-height});
  flex-direction: column; }

.main {
  flex: 1; }

h2, h3, h4, .is-before-list {
  @extend .mb-1, .pb-0; }

.is-after-paragraph {
  @extend .mt-0, .pt-0; }

// journal/blog page tweaks
#journal p {
  @extend .mb-4; }

// custom image handling to properly size images
.is-128wide {
  width: 128px !important; }

.is-200wide {
  width: 200px !important; }

.is-300wide {
  width: 300px !important; }


//text formatting tweaks
h2.subtitle {
  @extend .has-bottom-margin-5, .has-bottom-padding-5, .has-top-margin-15, .has-top-padding-15; }

// format all code tags with special styling when in how-to lists
.is-how-to code {
  @extend .has-text-primary; }

// how-to steps image formatting
.how-to-steps .image {
  @extend .ml-0, .pl-0; }


// changes color when rolled
.icon.has-text-primary.is-hovered:hover {
  color: $primary-hover !important; }

.has-padding-med {
  padding-left: $padding-medium;
  padding-right: $padding-medium; }

.has-vertical-padding-med {
  padding-top: $padding-medium;
  padding-bottom: $padding-medium; }

// logo styles
.has-branding-font {
  font-family: acumin-pro-extra-condensed, sans-serif; }

.has-branding-text {
  font-weight: 300;
  font-size: 60px; }

// general color additions
.has-text-secondary {
  color: $color-primary2 !important; }

.has-welcome-text {
  font-weight: 600;
  font-size: 30px; }

// general layout additions
.is-horizontal-center {
  justify-content: center; }


// footer link
.footer-link-base {
  color: $color-primary1; }

.footer-link-title {
  @extend .footer-link-base;
  font-size: 1.15rem;
  font-weight: 600;
  margin-top: 1.5rem; }

.footer-link {
  @extend .footer-link-base; }

.footer-link-title a, .footer-link a {
    color: currentColor; }

.footer-link-title a:hover, .footer-link a:hover {
    color: $light-primary1; }

// additional helper modifications
.is-clickable {
  cursor: pointer !important; }

// alternative size imports



// navigation bar updates
// hide icons when in the burger
@media screen and (max-width: $desktop - 1px) {
  .navbar-icon {
    display: none !important; } }

// homepage hero image background
.has-hero-bg-img {
  background: url('https://www.gearhowto.com//assets/images//home-hero-bg-image.jpg')center center;
  background-size: cover; }

// journal hero image background
.has-journal-hero-bg-img {
  background: url('https://www.gearhowto.com//assets/images/journal/journal-home-image.jpg')center center;
  background-size: cover; }
